<style>
  .padding {
    padding: 1rem
  }
  .languagepicker{
    max-width: 100px;
    position: relative;
    margin-right: 0;
    margin-left: auto;
  }
  .spinner {
    text-align: center;
    padding: 50px;
  }
  .footer-end {
    padding: 2px 1rem !important;
  }
  .footer-bar {
    min-height: 84px;
  }
</style>

<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      prominent
    >
      <v-spacer></v-spacer>
      <template v-slot:extension>
        <v-container>
          <v-row>
            <v-col>
              <v-tabs
                  v-model="tab"
                  align-with-title
                  background-color="transparent">
                <v-tabs-slider color="secondary"></v-tabs-slider>
                <v-tab
                    color="transparent"
                    v-for="page in pages"
                    :key="page.pageId + page.lang"
                    v-show="page.lang === language"
                >
                  {{ page.pageTitle }}
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <div class="d-flex align-center">
        <v-img
          alt="Logo"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
        />
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <v-container>
        <div class="spinner" v-if="tab === null">
          <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </div>
        <v-row>
          <v-col>
            <div class="languagepicker" v-show="tab !== null">
              <v-select
                  v-model="language"
                  :items="items"
                  label="Language"
                  dense
                  outlined
              ></v-select>
            </div>
            <v-tabs-items v-model="tab">
              <v-tab-item
                  v-for="page in pages"
                  :key="page.pageId + page.lang">
                <div v-for="(partial, idx) in page.pageContent" :key="idx">
                  <div v-if="partial.type === 'paragraph'" class="padding">
                    <p v-for="(paragraph, idx) in partial.content" :key="idx" v-html="paragraph[0]">
                    </p>
                  </div>
                  <v-list v-if="partial.type === 'list'" dense>
                    <v-list-item v-for="item in partial.content" :key="item[0]">
                      <v-list-item-content>
                        <v-list-item-title>{{ item[0] }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-list v-if="partial.type === 'linklist'">
                    <v-list-item v-for="item in partial.content" :key="item[0]" :href="item[1]" target="_blank">
                      <v-list-item-icon>
                        <v-icon v-text="'mdi-file-document'"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ item[0] }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <div v-if="partial.type === 'image'" class="padding" v-bind:style="{ float: partial.content[0][2] !== '' ? partial.content[0][2] : 'none' }">
                    <v-img
                           :src="partial.content[0][0]"
                           :max-width="partial.content[0][1]"
                    ></v-img>
                  </div>
                    <v-divider></v-divider>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer
        color="primary"
        padless
        fixed
        app
    >
      <v-card
          flat
          tile
          width="100%"
          class="primary text-center"
      >
        <v-card-text class="footer-bar">
          <v-btn
              v-for="action in footerActions"
              :key="action.icon"
              class="ma-2" tile outlined color="white"
              :href="action.link"
          >
            <v-icon left size="24px" color="white">{{ action.icon }}</v-icon>
            {{ action.text }}
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text secondary footer-end">
          {{ new Date().getFullYear() }} — <strong>Stephan Holländer</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>

import Sheets from "./services/sheets";
//import parse from "url-parse";

export default {
  name: 'App',
  components: {},
  data: function() {
    let pages = [];
    let footerActions = [];

    Sheets.GetPages().then((pagesJson) => {
      const params = this.getUrlParams();
      pages.push(...pagesJson);
      this.lang = params['tab'] ? pages[params['tab']].lang : 'de';
      this.tab = params['tab'] ? parseInt(params['tab'], 10) : null;
    });

    Sheets.GetStore().then((store) => {
      footerActions.push(...[
        {
          icon: 'mdi-email',
          link: store['mail'],
          text: store['mail_text'],
        },
        {
          icon: 'mdi-phone',
          link: store['tel'],
          text: store['tel_text'],
        },
      ]);
    });

    return {
      tab: null,
      lang: null,
      items: [
        { text: 'DE', value: 'de' },
        { text: 'FR', value: 'fr' },
        { text: 'EN', value: 'en' },
      ],
      footerActions,
      pages,
    };
  },
  created: function() {
  },
  watch: {
    tab: function (val) {
      const baseURL = window.location.href.split('?')[0];
      const params = this.getUrlParams();
      params['tab'] = val;
      const searchPart = Object.keys(params).map((key, index) => { return `${key}=${Object.values(params)[index]}`; }).join('&');
      history.pushState({}, '', `${baseURL}?${searchPart}`);
    },
  },
  methods: {
    getUrlParams: function() {
      const params = {};
      window.location.search.split('&').forEach((part) => {
        const param = part.split('=');
        if (param[0].replace('?', '') !== '') {
          params[param[0].replace('?', '')] = param[1];
        }
      });
      return params;
    },
  },
  computed: {
    language: {
      // getter
      cache: false,
      get: function() {
        return this.lang;
      },
      // setter
      set: function(newValue) {
        this.lang = newValue;
        this.tab = this.pages[this.tab].languageLinks[newValue];
        this.$forceUpdate();
      }
    }
  },
};
</script>
