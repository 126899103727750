export default class Sheets {
    static API_KEY = 'AIzaSyCEZJ8nJEeGCXFDOhDUNJK17AdwfEmUcSI';
    static API_TARGET = 'https://sheets.googleapis.com/v4/spreadsheets/';
    static SPREADSHEET_ID = '1DKY_9b5HxzkhiMrCG2g06iHv9m0zxIG5RqBP-uzo2mg';

    static async GetPages() {
        // GET request using fetch with async/await
        const response = await fetch(`${Sheets.API_TARGET}${Sheets.SPREADSHEET_ID}/values/inhalt?key=${Sheets.API_KEY}`);
        const data = await response.json();

        const languages = data.values[0].filter((item, index) => index % 2 === 0);
        const pages = [];

        const pageContents = await Sheets.GetPageRanges(
            data.values.slice(1, data.values.length).flatMap(tuple => tuple.filter((x, i) => i % 2  === 0)));

        let pageIndex;

        for(let i = 1; i < data.values.length; i++) {
            pageIndex = pages.length;
            for (let h = 0; h < data.values[i].length / 2; h++) {
                const languageLinks = {}
                languages.forEach((lang, idx) => {
                    if (idx !== h) {
                        languageLinks[lang] = pageIndex + idx;
                    }
                });
                pages.push({
                    lang: languages[h],
                    languageLinks,
                    pageId: data.values[i][h*2],
                    pageTitle: data.values[i][h*2 + 1],
                    pageContent: pageContents[pageIndex + h],
                });
            }
        }
        return pages;
    }

    static async GetPageRanges(pageIds) {
        // GET request using fetch with async/await
        const response = await fetch(`${Sheets.API_TARGET}${Sheets.SPREADSHEET_ID}/values:batchGet?key=${Sheets.API_KEY}&ranges=${pageIds.join('&ranges=')}`);
        const data = await response.json();
        const contentRanges = [];
        if (data.valueRanges) {
            data.valueRanges.forEach((range) => {
                const content = [];
                range.values.forEach((tuple) => {
                    if (tuple[0] !== '') {
                        content.push({
                            type: tuple[0],
                            content: [tuple.slice(1)]
                        })
                    } else {
                        content[content.length - 1].content.push(tuple.slice(1));
                    }
                });
                contentRanges.push(content);
            });
        }
        return contentRanges;
    }

    static async GetStore() {
        // GET request using fetch with async/await
        const response = await fetch(`${Sheets.API_TARGET}${Sheets.SPREADSHEET_ID}/values/store?key=${Sheets.API_KEY}`);
        const data = await response.json();
        const store = {};
        if (data.values) {
            data.values.forEach((tuple) => {
                store[tuple[0]] = tuple[1];
            });
        }
        return store;
    }
}